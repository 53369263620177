/**
 * Copyright 2021 TM9657 GmbH. All Rights Reserved.
 *
 * Custom button with red and gray variation.
 */

import styled from 'styled-components';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: 2,
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

export default function CustomButton(props: any) {
    const classes = useStyles();
    const { redButton, loading, disabled } = props;
    return (
        <ButtonWrapper>
            <div className={classes.wrapper}>
                <Button
                    variant="contained"
                    color="primary"
                    classes={redButton ? { root: 'redBtn' } : { root: 'btn' }}
                    disabled={loading || disabled}
                    onClick={props.onClick}
                    style={{ width: '100%' }}
                >
                    {props.children}
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
        </ButtonWrapper>
    );
}

const ButtonWrapper = styled.div`
    padding: 0.4rem;
    font-family: 'Mulish', sans-serif;
    width: 100%;
    .btn {
        background: #4f5463;
        color: #ffffff;
        font-weight: 700;
        font-size: 16px;
        font-family: 'Mulish', sans-serif;
        padding-top: 10px;
        padding-bottom: 10px;
        text-transform: capitalize;
        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2), inset 0px -2px 2px rgba(0, 0, 0, 0.15),
            inset 0px 2px 2px rgba(255, 255, 255, 0.07);
    }
    .btn:hover {
        background: #3f4451;
        box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.15), inset 0px 2px 2px rgba(255, 255, 255, 0.07);
    }
    .redBtn {
        font-family: 'Mulish', sans-serif;
        background-color: #d62d37;
        font-size: 16px;
        font-weight: 700;
        padding-top: 10px;
        padding-bottom: 10px;
        text-transform: capitalize;
        color: #cfd7ea;
        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2), inset 0px -2px 2px rgba(0, 0, 0, 0.25),
            inset 0px 2px 2px rgba(255, 255, 255, 0.15);
    }
    .redBtn:hover {
        background-color: #c0222c;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -2px 2px rgba(0, 0, 0, 0.25),
            inset 0px 2px 2px rgba(255, 255, 255, 0.15);
    }
    .MuiButton-contained.Mui-disabled {
        background: #262933;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -2px 2px rgba(0, 0, 0, 0.15),
            inset 0px 2px 2px rgba(255, 255, 255, 0.07);
        border-radius: 6px;
        color: #616776;
        cursor: not-allowed;
    }
`;
