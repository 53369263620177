/**
 * Copyright 2021 TM9657 GmbH. All Rights Reserved.
 *
 * Template, implementation up to you.
 */

/* eslint-disable @next/next/no-img-element */

import styles from '../../styles/header.module.css';
import { useRouter } from 'next/router';
import ReactGA from 'react-ga';
import {useState} from "react";

ReactGA.initialize("G-3GDVS9HFRZ");
export default function Header(props: any) {
    const router = useRouter();
    const [hover, setHover] = useState(false)

    ReactGA.event({ category: 'Form', action: 'Local Error', label: 'Too many Requests' });

    return (
        <nav className={styles.header}>
            <div className="container mx-auto flex justify-around items-center p-4">
                <div className={`${styles.portal} md:px-6 md:py-3 py-2 px-4`}>
                    <a
                        href="https://portal.ca9.io/docs"
                        target="_blank"
                        onClick={(e) => {
                            ReactGA.event({ category: 'Docs', action: 'Navigation', label: 'Open Docs' });
                        }}
                        className={`${styles.docs} text-white sm:text-2xl md:text-3xl font-bold`}
                    >
                        Docs
                    </a>
                </div>
                <div
                    onClick={(e) => {
                        ReactGA.event({ category: 'Docs', action: 'Navigation', label: 'Open Default Page' });

                        router.push('/');
                    }}
                    className="w-28 h-28 lg:w-40 lg:h-40 cursor-pointer"
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    <a href={"/"}>
                        {!hover && <img className="w-full h-full" src="/assets/logo.svg" alt=""/>}
                        {hover && <img className="w-full h-full" src="/assets/logoHover.svg" alt=""/>}
                    </a>
                </div>

                <div className={`${styles.portalMain} md:px-6 md:py-3 py-2 px-4`}>
                    <a
                        href="https://portal.ca9.io"
                        target="_blank"
                        className={`${styles.docs} text-white sm:text-2xl md:text-3xl font-bold`}
                        onClick={(e) => {
                            ReactGA.event({ category: 'Portal', action: 'Navigation', label: 'Open Portal' });
                        }}
                    >
                        Portal
                    </a>
                </div>
            </div>
            {/* absolutely positioned animated elements */}
            {/*<div className={`${styles.glowingStar} hidden lg:block`}>*/}
            {/*    <BigStar />*/}
            {/*</div>*/}
            {/*<div className={`${styles.glowingSmallStar} hidden lg:block`}>*/}
            {/*    <SmallStar />*/}
            {/*</div>*/}
        </nav>
    );
}
