/**
 * Copyright 2021 TM9657 GmbH. All Rights Reserved.
 *
 * Landing, Home Page. Keep this clean and lean
 * use components where possible
 */

import {useEffect, Suspense, useState, useCallback, useLayoutEffect} from 'react';
import Head from 'next/head';
import styles from '../styles/Home.module.css';

/**
 * import components
 */

import dynamic from 'next/dynamic';
import ReactGA from "react-ga4";
ReactGA.initialize("G-3GDVS9HFRZ");

import ThreeDBanner from "@components/sections/three";
import Header from "@components/sections/header";
import Section2 from "@components/sections/section2.value.prop";
import Section3 from "@components/sections/section3.selling.points";
//const ThreeDBanner = dynamic(() => import('@components/sections/three'));
// const Section2 = dynamic(() => import('@components/sections/section2.value.prop'));
// const Section3 = dynamic(() => import('@components/sections/section3.selling.points'));
const Section4 = dynamic(() => import('@components/sections/section4.exploring'));
const Section5 = dynamic(() => import('@components/sections/section5.build'));
const Section6 = dynamic(() => import('@components/sections/section6'));
const Section7 = dynamic(() => import('@components/sections/section7.form'));
const Section8 = dynamic(() => import('@components/sections/section8credits'));
// const Header = dynamic(() => import('@components/sections/header'));
const Footer = dynamic(() => import('@components/sections/footer'));
const Preloader = dynamic(() => import('@components/elements/preloader'));

import {motion} from 'framer-motion';
import {useRef} from "react";
import CustomButton from "@components/elements/custom.button";


export default function Home(props) {
    const [timeout, setTimeoutVar] = useState<any>();
    const [showPre, setShowPre] = useState(true);
    const [size, setSize] = useState({width: 0, height: 0})
    const [node, setNode] = useState(null);
    const [stars, setStars] = useState<{x: string, y: string, version: "1" | "2" | "3" | "4" | "5", color: string}[]>([]);

    const measuredRef = useCallback(node => {
        if (node !== null) {
            setNode(node);
            setSize({height: node.clientHeight, width: node.clientWidth});
        }
    }, []);

    useLayoutEffect(() => {
        if(node){
            const measure = () => {
                setSize({height: node.clientHeight, width: node.clientWidth});

            }

            window.addEventListener("resize", measure );
            measure()

            return () => {
                window.removeEventListener("resize", measure );
            };
        }
    }, [node])

    useEffect(() => {
        if(size.width === 0 || size.height === 0) return;
        const randomIntegerInRange = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
        const randomColor = () => {
            let int = randomIntegerInRange(1, 7)
            return int === 5 ? "#4F5463" : int === 4 ? "#6C63FF" : int === 3 ? "#C0222C" : int === 2 ? "#FFFFFF" : "#4F5463";
        }
        const stars : {x: string, y: string, version: "1" | "2" | "3" | "4" | "5", color: string }[] = []
        for(let i = 0; i < 200; i++){
            stars.push({x: randomIntegerInRange(0, size.width - 15 ).toString(), y: randomIntegerInRange(0, size.height).toString(), version: randomIntegerInRange(1, 5).toString(), color: randomColor() })
        }
        setStars(stars)
    }, [size])

    useEffect(() => {
        clearTimeout(timeout);
        setTimeoutVar(
            setTimeout(async function () {
                setShowPre(false);
            }, 2000),
        );
        ReactGA.pageview('/');
    }, []);

    return (
        <>
            <Head>
                <meta name="keywords" content="metaverse, unreal engine 5, 3d, digital twin"/>
            </Head>
            {showPre ? (
                <Preloader />
            ) : (
                <motion.div>
                    <div ref={measuredRef} className={`${styles.wrapper} pb-12`}>
                        {stars.map(value => <div key={JSON.stringify(value)} className={`${styles[`star${value.version}`]} ${Math.random() < 0.75 ? styles["rotating"] : ""} ${Math.random() < 0.25 ? styles["scaling"] : ""}`} style={{top: `${value.y}px`, left: `${value.x}px`, background: value.color, animationDelay: `${Math.random() * 2}s`}}></div>)}
                        <div className={"z-10"}>
                            <Header />
                            <ThreeDBanner />
                            <Section2 />
                            <Section3 />
                        </div>
                    </div>
                    <div className={"z-10"}>
                        <Section4 />
                        <Section5 />
                        <Section6 />
                        <Section7 />
                        <Section8/>
                        <Footer />
                    </div>

                </motion.div>
            )}
        </>
    );
}
