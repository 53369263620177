import {Canvas} from '@react-three/fiber'
import {MathUtils} from 'three';
import styled from "styled-components"
import {useEffect, useRef, useState} from "react";
import { Vector3, VideoTexture} from "three";
import {
    Clone,
    PerspectiveCamera,
    useGLTF
} from "@react-three/drei";
import {getAbsolute} from "@lib/util";

const SCALE = 10000;
const FOV = 18;
const ASPECTRATIO = 1549/ 628;

export default function ThreeDBanner(props) {
    const group = useRef()
    const glb = useGLTF(getAbsolute('/assets/Threejs.glb'))
    const [texture, setTexture] = useState<VideoTexture>(null);
    const [scrollPosition, setPosition] = useState({scrollX: 0, scrollY: 0})
    const [screenSize, setScreensize] = useState({width: window.innerWidth, height: window.innerHeight})
    const [camera, setCamera] = useState(calcAspect())
    const camRef: any = useRef();

    function calcAspect() : {aspect: number, fov: number}{
        let camera = {aspect: 0, fov: 0}
        camera.aspect = window.innerWidth / window.innerHeight;

        console.log(window.innerHeight + " " + window.innerWidth)

        if (camera.aspect > ASPECTRATIO) {
            // window too narrow
            camera.fov = FOV;
        } else {
            // window too large
            const cameraHeight = Math.tan(MathUtils.degToRad(FOV / 2));
            const ratio = camera.aspect / ASPECTRATIO;
            const newCameraHeight = cameraHeight / ratio;
            camera.fov = MathUtils.radToDeg(Math.atan(newCameraHeight)) * 2;
        }

        console.log(JSON.stringify(camera))

        return camera
    }

    useEffect(() => {
        function updatePosition() {
            setPosition({scrollX: window.scrollX, scrollY: window.scrollY})
        }

        function updateSize() {
            setScreensize({width: window.innerWidth, height: window.innerHeight})
            let cam = calcAspect();
            setCamera(cam)
        }

        const video = document.getElementById("myVideo")
        if (typeof video !== "undefined" && video !== null) setTexture(new VideoTexture(video as HTMLVideoElement));
        window.addEventListener('scroll', updatePosition)
        window.addEventListener("resize", updateSize)
        updatePosition()

        return () => {
            window.removeEventListener('scroll', updatePosition)
            window.removeEventListener("resize", updateSize)
        }
    }, [])

    return (
        <CanvasWrapper>
            {/*<p>{screenSize.height}</p>*/}
            <Canvas className={"canvas"} shadows={true}>
                {/*<OrbitControls/>*/}
                <PerspectiveCamera ref={camRef} manual makeDefault
                                   aspect={camera.aspect}
                                   fov={camera.fov - scrollPosition.scrollY / 200}
                                   onUpdate={(c) => {
                                       console.log("cam update")
                                       c.updateProjectionMatrix()
                                   }}
                >
                    <ambientLight color={"white"} intensity={2}/>
                    <directionalLight color={"white"} intensity={2}/>
                    <group
                        // scale={new Vector3(-1 * (0.05 + scrollPosition.scrollY / SCALE) * (screenSize.width / getScreenWidthMult()), (0.05 + scrollPosition.scrollY / SCALE) * (screenSize.width / getScreenWidthMult()), (0.05 + scrollPosition.scrollY / SCALE) * (screenSize.width / getScreenWidthMult()))}
                        scale={new Vector3(  (1 + scrollPosition.scrollY / SCALE), (1 + scrollPosition.scrollY / SCALE), (1 + scrollPosition.scrollY / SCALE))}
                        // rotation={[0 - scrollPosition.scrollY / ROTATE, 0, 0]}
                        ref={group} {...props}>
                        <mesh>
                            <Clone object={glb.scene}
                                   inject={<meshStandardMaterial emissiveIntensity={30} emissiveMap={texture}
                                                                 metalness={0} roughness={1} map={texture}/>}/>
                        </mesh>
                    </group>
                </PerspectiveCamera>
            </Canvas>
            <video className={"videoPrev"} muted={true} loop={true} id="myVideo" autoPlay={true}>
                <source src="/assets/Website-transcode.mp4" type="video/mp4"/>
            </video>
        </CanvasWrapper>
    )
}

const CanvasWrapper = styled.div`
  height: 80vh;

  @media screen and (max-width:600px) {
    /* Zusätzliche Regeln für kleinere Monitore  */
    height: 80vh;
    margin-top: -30vh;
    margin-bottom: -30vh;
  }
  
  .videoPrev {
    widht: 0;
    height: 0;
  }
`