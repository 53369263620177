/* eslint-disable @next/next/no-img-element */
/**
 * Copyright 2021 TM9657 GmbH. All Rights Reserved.
 *
 * Template, implementation up to you.
 */

import { useState, useEffect } from 'react';
import styles from '../../styles/section2.module.css';
import CustomButton from '@components/elements/custom.button';
import Typist from 'react-typist';
import styled from 'styled-components';

let counter = 0;

export default function Section2(props: any) {
    const [textOptions, setTextOptions] = useState({
        options: [
            'Digital Twin',
            'Simulations',
            'Gaming',
            'Game Development',
            'Virtual Training',
            'Industrial Planning',
            'Virtual Events',
            'Architectural Visualisation',
        ],
    });
    const [rand, setRand] = useState({ val: 1, len: 2 });
    const [currentText, setCurrentText] = useState(textOptions.options[0]);
    const [typing, setTyping] = useState(true);

    useEffect(() => {
        if (typing) return;

        setTyping(true);
    }, [typing]);

    return (
        <Section2Wrapper>
            <section className={`my-10 mx-3 ${styles.section2}`}>
                <div className="container mx-auto flex flex-col align-middle">
                    <div className={`${styles.wrapper} py-10 px-16 mx-auto`}>
                        <h1 className={"Header text- mb-10 text-3xl font-black text-center md:text-3xl  tracking-wide text-white"}>DELIVERING YOUR <h1 className={"Header Buzzword"}>BUZZWORDS</h1></h1>
                        <h2 className="text-justify text-xl md:text-xl font-bold tracking-wide text-white">
                            <span className={styles.primaryColor}>CA9</span> <b className={""}>(Connected Authoring 9latform)</b> is a 3D
                            authoring tool built on and compatible with Unreal Engine, but with a strong focus on
                            beginner friendliness with expert integration.
                            <br />
                        </h2>
                        <h1 className="text-center py-5 text-xl md:text-3xl font-black tracking-wide text-white">
                            The perfect fit for your.. <br />
                            {typing ? (
                                <Typist
                                    onTypingDone={() => {
                                        setTyping(false);

                                        let option =
                                            textOptions.options[Math.floor(Math.random() * textOptions.options.length)];

                                        while (option === currentText) {
                                            option =
                                                textOptions.options[
                                                    Math.floor(Math.random() * textOptions.options.length)
                                                ];
                                        }

                                        setCurrentText(option);
                                        setRand({
                                            val: Math.random(),
                                            len: Math.ceil(Math.random() * (option.length - 1)) / 2,
                                        });
                                    }}
                                >
                                    <Typist.Delay ms={200} />
                                    <span className={styles.useCase + ' text-xl h-10 md:text-3xl font-black'}>
                                        {currentText}
                                    </span>
                                    <Typist.Delay ms={1000} />
                                </Typist>
                            ) : (
                                ''
                            )}
                        </h1>
                        <div className="flex flex-row justify-center my-4 ">
                            <a className="w-1/2" href="https://portal.ca9.io/" target="blank">
                                <CustomButton redButton>{'➤➤ Start'}</CustomButton>
                            </a>

                            <a className="w-1/2" href="https://portal.ca9.io/license" target="blank">
                                <CustomButton>{'➤➤ Pricing'}</CustomButton>
                            </a>
                        </div>
                    </div>
                    <div className="justify-center my-10 flex font-semibold">
                        <img src="/assets/spaceship.svg" alt="" />
                    </div>
                </div>
            </section>
        </Section2Wrapper>
    );
}

const Section2Wrapper = styled.div`
  z-index: 5;
  .container{
    background: #1e2128;
  }
  
  .Header{
    font-style: normal;
    font-size: 2.5rem;
    line-height: 3rem;
    /* identical to box height */
    font-weight: 900;
  }
  
    .Typist .Cursor {
        display: inline-block;

        &--blinking {
            opacity: 1;
            animation: blink 1s linear infinite;
            @keyframes blink {
                0% {
                    opacity: 1;
                }
                50% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
        }
    }
`;
