/**
 * Copyright 2021 TM9657 GmbH. All Rights Reserved.
 *
 * Template, implementation up to you.
 */

import { useState } from 'react';
import Grid from '@mui/material/Grid';
import styles from '../../styles/section3.module.css';
import styled from "styled-components"
import { motion } from 'framer-motion';

export default function Section3(props: any) {
    const [info, setInfo] = useState({ 1: false, 2: false, 3: false, 4: false });
    const showVariant = {
        open: { display: 'block', opacity: 1 },
        closed: { display: 'none', opacity: 0 },
    };
    const rotateVariant = {
        open: { rotate: 180 },
        closed: { rotate: 0 },
    };
    return (
        <SellingPointWrapper>
            <section className={`${styles.section3}`}>
                <div className={"sizeLimiter"}>
                    <Grid container>
                        <Grid item xs={12} md={6} lg={3}>
                            <div
                                onClick={() => setInfo({ 1: !info[1], 2: false, 3: false, 4: false })}
                                className={`${styles.card} p-8 m-4 relative`}
                            >
                                <h1
                                    className={`${styles.primaryColor} font-black tracking-wide mb-8 text-center text-3xl px-6 `}
                                >
                                    Content <br /> Universe
                                </h1>
                                <p className="font-black tracking-wide my-4 text-white text-xl ">
                                    All resources you might need in one place. Quality controlled, up to date and complete
                                </p>
                                <motion.ul
                                    className="font-black tracking-wide my-4 text-white text-xl list-disc pl-4 mt-8"
                                    animate={info[1] ? 'open' : 'closed'}
                                    variants={showVariant}
                                    transition={{ duration: 0.5 }}
                                >
                                    <li className="my-4">constantly growing store</li>
                                    <li className="my-4">quality and security control</li>
                                    <li className="my-4">local server support (enterprise)</li>
                                </motion.ul>
                                <button onClick={() => setInfo({ 1: !info[1], 2: false, 3: false, 4: false })}>
                                    <motion.img
                                        animate={info[1] ? 'open' : 'closed'}
                                        variants={rotateVariant}
                                        transition={{ duration: 0.5 }}
                                        src="/assets/download.svg"
                                        alt=""
                                        className={styles.button}
                                    />
                                </button>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <div
                                onClick={() => setInfo({ 1: false, 2: !info[2], 3: false, 4: false })}
                                className={`${styles.card} p-8 m-4 relative`}
                            >
                                <h1
                                    className={`${styles.primaryColor} font-black tracking-wide mb-8 text-center text-3xl px-6 `}
                                >
                                    <b className={"Buzzword"}>High Speed</b> <br /> Launch
                                </h1>
                                <p className="font-black tracking-wide my-4 text-white text-xl ">
                                    <b className={"Buzzword"}>No Code</b>, lean, efficient project structure, no external experts necessary. Create and launch
                                    faster and cheaper!
                                </p>
                                <motion.ul
                                    className="font-black tracking-wide my-4 text-white text-xl list-disc pl-4 mt-8"
                                    animate={info[2] ? 'open' : 'closed'}
                                    variants={showVariant}
                                    transition={{ duration: 0.5 }}
                                >
                                    <li className="my-4">user first without compromise</li>
                                    <li className="my-4">you logic without code</li>
                                    <li className="my-4">beginner friendly, expert suitable</li>
                                </motion.ul>
                                <button onClick={() => setInfo({ 1: false, 2: !info[2], 3: false, 4: false })}>
                                    <motion.img
                                        animate={info[2] ? 'open' : 'closed'}
                                        variants={rotateVariant}
                                        transition={{ duration: 0.5 }}
                                        src="/assets/download.svg"
                                        alt=""
                                        className={styles.button}
                                    />
                                </button>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <div
                                onClick={() => setInfo({ 1: false, 2: false, 3: !info[3], 4: false })}
                                className={`${styles.card} p-8 m-4 relative`}
                            >
                                <h1
                                    className={`${styles.primaryColor} font-black tracking-wide mb-8 text-center text-3xl px-6 `}
                                >
                                    Fast as a <br /> Rocket
                                </h1>
                                <p className="font-black tracking-wide my-4 text-white text-xl ">
                                    Not rocket science but fast as a rocket. We integrate the best for you, powered by <b className={"Buzzword"}>Unreal
                                    Engine 5</b>
                                </p>
                                <motion.ul
                                    className="font-black tracking-wide my-4 text-white text-xl list-disc pl-4 mt-8"
                                    animate={info[3] ? 'open' : 'closed'}
                                    variants={showVariant}
                                    transition={{ duration: 0.5 }}
                                >
                                    <li className="my-4">handle large amounts of data</li>
                                    <li className="my-4">proven and robust foundation</li>
                                    <li className="my-4">import Unreal Engine 5 content</li>
                                </motion.ul>
                                <button onClick={() => setInfo({ 1: false, 2: false, 3: !info[3], 4: false })}>
                                    <motion.img
                                        animate={info[3] ? 'open' : 'closed'}
                                        variants={rotateVariant}
                                        transition={{ duration: 0.5 }}
                                        src="/assets/download.svg"
                                        alt=""
                                        className={styles.button}
                                    />
                                </button>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <div
                                onClick={() => setInfo({ 1: false, 2: false, 3: false, 4: !info[4] })}
                                className={`${styles.card} p-8 m-4 relative`}
                            >
                                <h1
                                    className={`${styles.primaryColor} font-black tracking-wide mb-8 text-center text-3xl px-6 `}
                                >
                                    Alien <br /> Technology
                                </h1>
                                <p className="font-black tracking-wide my-4 text-white text-xl ">
                                    Available for all sorts of Technology. Starting with the normal PC, VR, AR or completely in
                                    Web without strong hardware!
                                </p>
                                <motion.ul
                                    className="font-black tracking-wide my-4 text-white text-xl list-disc pl-4 mt-8"
                                    animate={info[4] ? 'open' : 'closed'}
                                    variants={showVariant}
                                    transition={{ duration: 0.5 }}
                                >
                                    <li className="my-4">integrated Browser Streaming</li>
                                    <li className="my-4">optimized for your hardware</li>
                                    <li className="my-4">VR and AR compatible</li>
                                </motion.ul>
                                <button onClick={() => setInfo({ 1: false, 2: false, 3: false, 4: !info[4] })}>
                                    <motion.img
                                        animate={info[4] ? 'open' : 'closed'}
                                        variants={rotateVariant}
                                        transition={{ duration: 0.5 }}
                                        src="/assets/download.svg"
                                        alt=""
                                        className={styles.button}
                                    />
                                </button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
        </SellingPointWrapper>

    );
}

const SellingPointWrapper = styled.div`
    .sizeLimiter{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      width: 125rem;
      max-width: 100%;
      margin: auto;
    }
`